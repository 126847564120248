/* global Promise */

'use strict';

angular.module('managerApp').controller('UsersController', (
        $scope, $location, $routeParams, $mdDialog,
        Auth, users, permissions, passwords, toast) => {

    const path = $location.path();

    $scope.showDeleteConfirm = ($event, user) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le compte de <b>` + user.name + `</b> ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                userId: user._id
            },
            controller: ($scope, $mdDialog, toast, users, userId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        users.delete(userId).then(() => {
                            toast.show('Le compte a bien été supprimé.', 'success');
                            getUsers();
                        }).catch((err) => {
                            if (err.message) {
                                toast.show(err.message, 'error', true);
                            } else {
                                toast.show('Une erreur est survenu lors de la suppression' +
                                        ' de ce compte utilisateur.', 'error', true);
                            }
                        });
                }
                };
            }
        });
    };


    $scope.userId = $routeParams.id;
    $scope.isEdit = typeof $scope.userId !== 'undefined';

    $scope.firstRun = true;
    $scope.count = 0;
    $scope.items = [];
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 5,
        totalItems: 0,
        pageChanged: () => {
            getUsers();
        }
    };
    $scope.error = null;
    $scope.selected = null;
    $scope.permissions = null;
    $scope.permissionsIndex = {};

    $scope.getPermissionClass = (permission) => {
        permission = permission.split(':');
        const p = permission[0],
                m = permission[1].toLowerCase();
        if (p === '*' & m === '*') {
            return 'admin-all';
        }
        if (m === '*') {
            return 'all';
        }
        return m;
    };
    $scope.getPermission = (permission) => {
        const permissionId = permission.split(':')[0];
        if (permissionId === '*') {
            return 'Super ADMIN';
        }
        permission = $scope.permissionsIndex[permissionId];
        return permission ? permission.name : 'Permission inconnue';
    };
    $scope.generatePassword = () => {
        $scope.user.password = passwords.generate({
            passwordLength: 10,
            uppercase: true,
            numbers: true,
            specials: true,
            similarChars: false
        });
    };
    $scope.togglePermission = (permission, method) => {
        let permissions = $scope.user._permissions;
        if (method) {
            if (typeof permissions[permission].values[method] === 'undefined') {
                permissions[permission].values[method] = true;
            } else {
                permissions[permission].values[method] =
                        !permissions[permission].values[method];
            }
            permissions[permission].checked = false;
            permissions[permission].indeterminated = false;
            let checkedNb = 0;
            for (let v in permissions[permission].values) {
                if (permissions[permission].values[v]) {
                    checkedNb++;
                }
            }
            permissions[permission].checkedNb = checkedNb;
            permissions[permission].checked =
                    permissions[permission].checkedNb === permissions[permission].totalNb;
            if (permissions[permission].checkedNb > 0) {
                if (permissions[permission].checkedNb < permissions[permission].totalNb) {
                    permissions[permission].indeterminated = true;
                }
            }
        } else {
            permissions[permission].checked = !permissions[permission].checked;
            if (permissions[permission].checked) {
                permissions[permission].indeterminated = false;
                permissions[permission].checkedNb = permissions[permission].totalNb;
            } else {
                permissions[permission].checkedNb = 0;
            }
            for (let v in permissions[permission].values) {
                permissions[permission].values[v] = permissions[permission].checked;
            }
        }
    };
    $scope.getPermissionMethodName = (method) => {
        switch (method) {
            case '*:*':
                return 'Super ADMIN';
            case '*':
                return 'Tout';
            case 'POST':
                return 'Ajout';
            case 'GET':
                return 'Récupération';
            case 'PUT':
                return 'Modification';
            case 'DELETE':
                return 'Suppression';
        }
    };
    $scope.submitForm = (form) => {
        let user = $scope.user, permissions = user._permissions;
        if (form.$valid) {
            user.permissions = [];
            let checkedTotal = 0;
            for (let pId in permissions) {
                let permission = permissions[pId];
                checkedTotal += permission.checkedNb;
                if (permission.checkedNb > 0) {
                    if (permission.checkedNb === permission.totalNb) {
                        user.permissions.push(pId + ':*');
                    } else {
                        for (let method in permission.values) {
                            if (permission.values[method]) {
                                user.permissions.push(pId + ':' + method);
                            }
                        }
                    }
                }
            }
            if (checkedTotal === 0) {
                toast.show('Veuillez sélectionner au moins une permission.', 'error');
                return;
            }
            user.role = user.isAdmin ? 'admin' : 'user';
            let promise = $scope.isEdit ? users.update(user) : users.add(user);
            promise.then(() => {
                toast.show('Le compte a bien été ' +
                        ($scope.isEdit ? 'mis à jour' : 'ajouté.'), 'success', true).then(() => {
                    if (!$scope.isEdit) {
                        $scope.firstRun = true;
                        $location.path('/users');
                    }
                });
            }).catch((err) => {
                toast.show(
                        err && err.message
                        ? err.message
                        : 'Une erreur est survenue lors de traitement de votre demande.',
                        'error', true);
            });
        } else {
            toast.show('Veuillez remplir tous les champs obligatoires.', 'error', true);
        }
    };
    $scope.setUserRole = () => {
        if (!$scope.user.isAdmin) {
            let permission = $scope.user._permissions['USERS'];
            permission.checked = false;
            permission.checkedNb = 0;
            permission.indeterminated = false;
            for (let m in permission.values) {
                permission.values[m] = false;
            }
        }
    };
    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };
    const getUsers = () => {
        users.get({}, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            if ($scope.firstRun)
                $scope.firstRun = false;
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };
    const getUser = (id = null) => {
        if (!id)
            return;
        users.get({id: id}).then((data) => {
            if ($scope.firstRun)
                $scope.firstRun = false;
            $scope.user = data;
            $scope.user.isAdmin = $scope.user.role === 'admin';
            initUserPermissions();
            setUserPermissions();
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };
    const setUserPermissions = () => {
        if ($scope.permissions.length > 0 && $scope.user) {
            if (!$scope.user._permissions) {
                return initUserPermissions();
            }
            let permissions = $scope.permissions;
            let usersPermissions = JSON.parse($scope.user.permissions);
            for (let i = 0; i < usersPermissions.length; i++) {
                const userPermission = usersPermissions[i].split(':'),
                        userPermissionId = userPermission[0],
                        userPermissionMethod = userPermission[1];
                if (userPermissionId === '*' && userPermissionMethod === '*') {
                    for (let p in $scope.user._permissions) {
                        let user_Permission = $scope.user._permissions[p];
                        user_Permission.checkedNb = user_Permission.totalNb;
                        user_Permission.checked = true;
                        for (let m in user_Permission.values) {
                            user_Permission.values[m] = true;
                        }
                    }
                } else {
                    let user_Permission = $scope.user._permissions[userPermissionId];
                    if (userPermissionMethod === '*') {
                        user_Permission.checkedNb = user_Permission.totalNb;
                        user_Permission.checked = true;
                        for (let m in user_Permission.values) {
                            user_Permission.values[m] = true;
                        }
                    } else {
                        user_Permission.values[userPermissionMethod] = true;
                        user_Permission.checkedNb++;
                        if (user_Permission.checkedNb === user_Permission.totalNb) {
                            user_Permission.checked = true;
                            user_Permission.indeterminated = false;
                        } else {
                            user_Permission.checked = false;
                            user_Permission.indeterminated = true;
                        }
                    }
                }
            }
        }
    };
    const initUserPermissions = () => {
        if ($scope.permissions.length > 0 && $scope.user) {
            $scope.user._permissions = {};
            let permissions = $scope.permissions;
            for (let i = 0; i < permissions.length; i++) {
                const p = permissions[i];
                let o = {
                    checked: false,
                    indeterminated: false,
                    totalNb: p.methods.length,
                    checkedNb: 0,
                    values: {}
                };
                for (let j = 0; j < p.methods.length; j++) {
                    o.values[p.methods[j]] = false;
                }
                $scope.user._permissions[p.id] = o;
            }
            $scope.$apply();
        }
    };
    const getPermissions = () => {
        return permissions.getPermissions().then((permissions) => {
            if ($scope.firstRun)
                $scope.firstRun = false;
            $scope.permissions = permissions;
            for (let i = 0; i < permissions.length; i++) {
                const p = permissions[i];
                $scope.permissionsIndex[p.id] = permissions[i];
            }
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };

    getPermissions().then(() => {
        switch (path.substr(0, 11)) {
            case '/users':
                getUsers();
                break;
            case '/users/add':
                $scope.user = {
                    name: 'Prénom NOM',
                    email: 'user@example.com',
                    password: 'A123456a.',
                    isAdmin: false,
                    sendEmail: false,
                    _permissions: {
                    }
                };
                initUserPermissions();
                break;
            case '/users/edit':
                getUser($scope.userId);
                break;
        }
    });
});
